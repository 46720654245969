<template>
  <ion-page class="page">
    <div style="display: flex; justify-content: center; align-items: center; height: 100%">
      <div style="text-align: center">
        <p style="font-size: 24px">Processing transaction, please wait.</p>
        <p>You will be redirected automatically.</p>
      </div>
    </div>
  </ion-page>
</template>

<script lang="ts" setup>
import { toast } from '@/shared/native';
import StripeService from '@/shared/services/stripe';
import { COMPLIMENT_BOMB, CANCEL } from '@/shared/statics/constants';
import { modalController } from '@ionic/vue';
import PurchaseSuccessModal from '@/shared/modals/PurchaseSuccessModal.vue';
import { authStore } from '../pinia-store/auth';
const stripeService = ref(null) as any;
const polling = ref(null) as any;
const loading = ref(true);
const pendingServerRequest = ref(false);
const { isFirstCharacterCreationChallenge } = authStore();

const {
  public: { stripeKey },
} = useRuntimeConfig();

useHead({
  meta: [
    {
      name: 'robots',
      content: 'noindex',
    },
  ],
});
onMounted(async () => {
  stripeService.value = StripeService.getInstance(stripeKey);
  await stripeService.value.load();
  const route = useRoute();
  const router = useRouter();
  let prevLoc = decodeURIComponent((route.query.from || '/?km_=1') as string);
  if (!prevLoc.includes('km_=1')) {
    if (prevLoc.includes('?')) prevLoc += '&km_=1';
    else prevLoc += '?km_=1';
  }
  const paymentIntent = route.query.payment_intent;
  const postAction = route.query.postAction as string;
  const productCode = route.query.productCode as string;
  const fpo = route.query.fpo as string;
  const paymentIntentSecret = route.query.payment_intent_client_secret;
  const redirectStatus = route.query.redirect_status;

  if (redirectStatus === 'succeeded') {
    let count = 0;
    polling.value = setInterval(async () => {
      if (pendingServerRequest.value) return;
      pendingServerRequest.value = true;
      try {
        const result = await stripeService.value.confirmItemCheckout(paymentIntent);
        if (result) {
          clearInterval(polling.value);
          polling.value = null;
          if (postAction === COMPLIMENT_BOMB) {
            const action = (await openPurchaseSuccessModal(productCode, postAction, fpo)) as any;
            if (action == 'inventory') {
              return router.replace({ name: 'home', query: { modal: 'compliment-bomb-hub' } });
            }
          } else if (postAction) {
            (await openPurchaseSuccessModal(productCode, postAction, fpo)) as any;
          }
          router.replace(prevLoc);
          return;
        } else if (result === false) {
          count += 1;
          if (count >= 30) {
            clearInterval(polling.value);
            alert('Checkout could not be confirmed. Please use our support chat.');
            loading.value = false;
            polling.value = null;
            router.replace(prevLoc + '&success=1');
          }
          return;
        }
      } catch (e) {
        console.error(e);
      } finally {
        pendingServerRequest.value = false;
      }
    }, 3000);
  } else if (redirectStatus === 'canceled') {
    toast.show('Transaction canceled by user', 'nonative', 'primary');
    return router.replace(prevLoc);
  } else if (redirectStatus === 'failed') {
    toast.show('Transaction denied: You have not been charged', 'nonative', 'primary');
    if (prevLoc.includes('redirectCharSlug') && prevLoc.includes('bundle')) {
      const searchParams = new URLSearchParams(prevLoc);
      const slug = searchParams.get('redirectCharSlug');

      if (isFirstCharacterCreationChallenge.value) {
        router.replace({ name: 'home' });
      } else {
        router.replace({
          name: 'character-profile-new',
          params: { slug: slug },
        });
      }
    } else {
      router.replace(prevLoc + '&failed=1' || '/?fromcheckout=1&failed=1');
    }
    return;
  } else if (redirectStatus === 'pending') {
    router.replace(prevLoc);
    toast.show('Transaction was canceled', 'nonative', 'primary');
    return;
  }
});

const openPurchaseSuccessModal = async (productCode: string, postAction: string, fpo: string) => {
  const modal = await modalController.create({
    component: PurchaseSuccessModal,
    cssClass: 'purchase-success-modal',
    componentProps: { postAction, productCode, firstPurchaseOffer: fpo },
  });

  modal.onDidDismiss().then((value) => {
    const route = useRoute();
    const router = useRouter();
    if (value.role === CANCEL && get(route.query, 'redirectCharSlug') && get(route.query, 'bundle')) {
      if (isFirstCharacterCreationChallenge.value) {
        router.replace({ name: 'home' });
      } else {
        router.replace({
          name: 'character-profile-new',
          params: { slug: get(route.query, 'redirectCharSlug') as any },
        });
      }
    }
  });
  const action = await modal.present();
  return action;
};
</script>

<style lang="sass" scoped></style>
